<template>
    <footer class="fm-contract m-0 flex w-full max-w-full justify-start overflow-x-auto p-5 md:justify-center">
        <div class="h-19 flex items-center justify-between gap-5 text-white md:h-24 md:w-11/12 xl:w-10/12">
            <img src="/assets/img/logow.svg" alt="" class="h-9 opacity-90" />
            <span class="line-clamp-2 min-w-[27rem] max-w-[40%] opacity-90" :title="describe">
                {{ describe }}
            </span>
            <div class="flex shrink-0 flex-col opacity-90">
                <a class="hover:underline" href="http://www.kooci.net" target="_blank">南京酷奇信息科技有限公司</a>
                <span>固定电话：025-83517331</span>
            </div>
            <div class="flex shrink-0 flex-col opacity-90">
                <span>
                    <a href="/legal-service-agreement.html" class="hover:underline" target="_blank">服务协议</a>
                    <span>&nbsp;|&nbsp;</span>
                    <a href="/legal-privacy-agreement.html" class="hover:underline" target="_blank">隐私协议</a>
                </span>
                <div class="flex items-center">
                    <img src="/imgs/ba.png" alt="备案图标" class="shrink-0 -ml-1 w-6 scale-[0.6] translate-y-1 origin-top" />
                    <a
                        href="https://beian.mps.gov.cn/#/query/webSearch?code=32011302322406"
                        class="cursor-pointer hover:underline"
                        rel="noreferrer"
                        target="_blank"
                    >
                        苏公网安备32011302322406 
                    </a>
                    &nbsp;|&nbsp;
                    <a
                        href="https://beian.miit.gov.cn/#/Integrated/index"
                        target="_blank"
                        class="cursor-pointer hover:underline"
                    >
                        苏ICP备18048000号-2
                    </a>
                </div>
            </div>
            <div class="flex items-center gap-2 opacity-80">
                <!-- 小红书 -->
                <ElPopover trigger="hover">
                    <img src="/assets/img/xiaohongshu.jpg" alt="小红书号：2081067912" />
                    <template #reference>
                        <Icon
                            class="cursor-pointer transition-transform hover:scale-125"
                            icon="icon-xiaohongshu"
                            size="2"
                            title="小红书号：2081067912"
                        />
                    </template>
                </ElPopover>
                <!-- 抖音 -->
                <ElPopover trigger="hover">
                    <img src="/assets/img/douyin.jpg" alt="抖音号:38778432691" />
                    <template #reference>
                        <Icon
                            class="cursor-pointer transition-transform hover:scale-125"
                            icon="icon-douyinzhanghao"
                            size="2"
                            title="抖音号:38778432691"
                        />
                    </template>
                </ElPopover>
                <!-- 新浪微博 -->
                <ElPopover trigger="hover">
                    <img src="/assets/img/weibo.jpg" alt="@找导师网" />
                    <template #reference>
                        <Icon
                            class="cursor-pointer transition-transform hover:scale-125"
                            icon="icon-xinlangweibo"
                            size="2"
                            title="@找导师网"
                        />
                    </template>
                </ElPopover>
                <!-- 客服 -->
                <ElTooltip content="咨询客服">
                    <Icon
                        class="cursor-pointer transition-transform hover:scale-125"
                        icon="icon-kefu-full"
                        size="2"
                        @click="openKefu"
                    />
                </ElTooltip>
                <!-- 邮箱 -->
                <ElTooltip content="发邮件:1837524478@qq.com">
                    <Icon class="cursor-pointer transition-transform hover:scale-125" icon="icon-mail2-full" size="2" />
                </ElTooltip>
            </div>
        </div>
        <KefuDialog v-model:visible="kefuVisible" />
    </footer>
</template>

<script setup lang="ts">
const kefuVisible = ref(false);
function openKefu() {
    kefuVisible.value = true;
}

const describe =
    "找导师网是一家致力于提供高校教授1对1考研辅导的专业服务平台，量身定制备考策略，与教授面对面交流，为您的上岸之路保驾护航！";
</script>
